import { useNavigate } from 'react-router-dom';
import { NavBar } from 'antd-mobile';

function CoustomNavBar(props) {
  const { back, title, backgroundColor } = props;
  const navigate = useNavigate();
  const onBack = () =>{
    navigate(-1);
  }
  return (
    <>
      <NavBar 
        back={back} 
        onBack={onBack} 
        style={{ 
          color: '#fff',
          backgroundColor: backgroundColor,
          position: 'sticky',
          top: '0px',
        }}
        className='nav-bar-custom'
      >
        <span className="nav-bar-title-custom">{title}</span>
      </NavBar>
    </>
  )
};

export default CoustomNavBar;