import { useNavigate } from 'react-router-dom';
import CoustomNavBar from '@/components/navbar.js';
import { Grid, Image } from 'antd-mobile';

function HomePage() {
  const navigate = useNavigate();

  const jump_page = (path) => {
    navigate(path);
  }

  return (
    <>
      <CoustomNavBar back={null} title="E星基地" backgroundColor="#f54c3b" />
      <Grid columns={2} gap={32} className='grid-custom'>
        <Grid.Item>
          <Image src={require('@/assets/home/a.jpg')} onClick={() => jump_page('/part-a')}></Image>
        </Grid.Item>
        <Grid.Item>
          <Image src={require('@/assets/home/b.jpg')} onClick={() => jump_page('/part-b')}></Image>
        </Grid.Item>
        <Grid.Item>
          <Image src={require('@/assets/home/c.jpg')} onClick={() => jump_page('/part-d')}></Image>
        </Grid.Item>
        <Grid.Item>
          <Image src={require('@/assets/home/d.jpg')} onClick={() => jump_page('/part-c')}></Image>
        </Grid.Item>
      </Grid>
    </>
  );
}

export default HomePage;