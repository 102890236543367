import { useState, useEffect } from 'react';
import CoustomNavBar from '@/components/navbar.js';
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Image, ErrorBlock } from 'antd-mobile';
import { getArticleList } from '@/apis';

const emptyImage = require('@/assets/empty.png');
function ArticleList() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [articleList, setArticleList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getArticleList(state?.typeId).then(res => {
      setArticleList(res.data?.param || [])
    })
  }, [])

  const jumpToDetail = (id) => {
    navigate('/article-detail', {
      state: {
        id
      }
    })
  }


  return (
    <>
      <CoustomNavBar back="" title={state?.title} backgroundColor="#A60A10" />
      <div className='article-list-container'>
        <Grid columns={2} gap={[20, 30]}>
          {
            articleList.map((item, index) => {
              return (
                <Grid.Item key={index} onClick={() => jumpToDetail(item.id)}>
                  <Image src={item.image} fit='cover' lazy={true} className='article-coverImg'></Image>
                  <div className='article-title'>{item.title}</div>
                </Grid.Item>
                )
            })
          }
        </Grid>
        {
          articleList.length === 0 && <ErrorBlock image={emptyImage} title="暂无文章" style={{ '--image-height': '200px'}} description={''} />
        }
      </div>
    </>
  )
};

export default ArticleList;