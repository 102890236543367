import CoustomNavBar from '@/components/navbar.js';
import { useNavigate } from 'react-router-dom';
import { Image, Grid } from 'antd-mobile';

const aaImage = require('@/assets/a/aa.png');
const abImage = require('@/assets/a/ab.png');
const acImage = require('@/assets/a/ac.png');
function PartA() {
  const navigate = useNavigate();

  const jumpToArticle = (title, typeId) => {
    navigate('/article', { state: { title, typeId }} )
  }
  const jumpToPartyMember = () => {
    navigate('/party-member')
  }
  return (
    <>
      <CoustomNavBar back="" title="E星基地 —— 掌上党建" backgroundColor="#A60A10" />
      <Image className='page-main-image' src={require('@/assets/a/a.png')} />
      <Grid columns={3} gap={24} className='grid-custom'>
        <Grid.Item onClick={() => jumpToArticle('掌上党建 —— 学习活页', 'aa')}>
          <Image src={aaImage} className='w-full'></Image>
          <div className='text-center'>学习活页</div>
        </Grid.Item>
        <Grid.Item onClick={jumpToPartyMember}>
          <Image src={abImage} className='w-full'></Image>
          <div className='text-center'>党员风采</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticle('掌上党建 —— 先锋献力', 'ac')}>
          <Image src={acImage} className='w-full'></Image>
          <div className='text-center'>先锋献力</div>
        </Grid.Item>
      </Grid>
    </>
  )
};

export default PartA;