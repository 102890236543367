import CoustomNavBar from '@/components/navbar.js';
import { useNavigate } from 'react-router-dom';
import { Image, Grid } from 'antd-mobile';

const daImage = require('@/assets/d/da.png');
const dbImage = require('@/assets/d/db.png');
const dcImage = require('@/assets/d/dc.png');
const ddImage = require('@/assets/d/dd.png');
function PartD() {
  const navigate = useNavigate();

  const jumpToArticle = (title, typeId) => {
    navigate('/article', { state: { title, typeId }} )
  }

  return (
    <>
      <CoustomNavBar back="" title="E星基地 —— 掌上代表室" backgroundColor="#E81F27" />
      <Image className='page-main-image' src={require('@/assets/d/d.png')} />
      <Grid columns={2} gap={24} className='grid-custom'>
        <Grid.Item onClick={() => jumpToArticle('掌上代表室 —— 代表理论', 'da')}>
          <Image src={daImage} className='w-full' ></Image>
          <div className='text-center'>代表理论</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticle('掌上代表室 —— 代表力量', 'db')}>
          <Image src={dbImage} className='w-full' ></Image>
          <div className='text-center'>代表力量</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticle('掌上代表室 —— 代表献力', 'dc')}>
          <Image src={dcImage} className='w-full' ></Image>
          <div className='text-center'>代表献力</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticle('掌上代表室 —— 代表建言', 'dd')}>
          <Image src={ddImage} className='w-full' ></Image>
          <div className='text-center'>代表建言</div>
        </Grid.Item>
      </Grid>
    </>
  )
};

export default PartD;