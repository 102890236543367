import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { getArticleDetail } from '@/apis';
import CoustomNavBar from '@/components/navbar.js';
function ArticleDetail() {
  const location = useLocation();
  const { state } = location;

  const [articleDetail, setArticleDetail] = useState({});

  useEffect(() => {
    getArticleDetail(state?.id).then(res => {
      console.log(res.data.param.content);
      let param = res.data.param;
      param.content = param.content.replace(/<img src="\//g, '<img src="https://smart.cmvstv.com/');
      setArticleDetail(param || {});
    })
  }, [])

  return (
    <>
      <CoustomNavBar back="" title={articleDetail.title} backgroundColor={state.type ? '#01052C' : "#A60A10"} />
      <div className='article-detail-box'>
        {/* <div className="article-detail-title">{articleDetail.title}</div>
        <Divider style={{borderColor: '#ccc'}}/> */}
        <div className='article-detail-content' dangerouslySetInnerHTML={{__html: articleDetail.content}}></div>
      </div>
    </>
  )
};

export default ArticleDetail;