import axios from 'axios';

const baseUrl = "https://api.cmvstv.com/platform/v1";

const request = axios.create({
  baseURL: baseUrl,
  timeout: 5000,
});

// 获取文章列表
export const getArticleList = (typeId) => request.get('/getCurrency', { params: { typeId } });

// 获取党员风采列表
export const getPartyMemberList = () => {
  return new Promise((resolve, reject) => {
    axios.get('https://sadmin.cmvstv.com/smartapi/index/getBaseParty').then(res => {
      resolve(res.data)
    }).catch(err => {
      console.log(err)
      reject(err)
    })
  })
}
// 获取党员详情
export const getPartyMemberDetail = (id) => request.get('/getBasePartyDetailed', { params: { id } });

// 获取同心风采列表
export const getPartyCoalitionList = () => request.get('/getConcentricList');
// 获取同心详情
export const getPartyCoalitionDetail = (id) => request.get('/getConcentricDetailed', { params: { id } });
// 获取文章详情
export const getArticleDetail = (id) => request.get('/getBaseNew', { params: { id } });