import CoustomNavBar from '@/components/navbar.js';
import { useNavigate } from 'react-router-dom';
import { Image, Grid } from 'antd-mobile';

const caImage = require('@/assets/c/ca.jpg');
const cbImage = require('@/assets/c/cb.jpg');
const ccImage = require('@/assets/c/cc.jpg');
const cdImage = require('@/assets/c/cd.jpg');
function PartC() {
  const navigate = useNavigate();

  const jumpToArticle = (title, typeId) => {
    navigate('/article-partc', { state: { title, typeId }} )
  }

  const jumpToArticleDetail = (id) => {
    navigate('/article-detail', {
      state: {
        id,
        type: 'c'
      }
    })
  }

  return (
    <>
      <CoustomNavBar back="" title="E星基地 —— 马栏山网络联谊会" backgroundColor="#01052C" />
      <Image className='page-main-image' src={require('@/assets/c/c.jpg')} />
      <Grid columns={2} gap={24} className='grid-custom'>
        <Grid.Item onClick={() => jumpToArticle('马栏山网络联谊会 —— 网联会简介', 'ca')}>
          <Image src={caImage} className='w-full' ></Image>
          <div className='text-center'>网联会简介</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticleDetail(385)}>
          <Image src={cdImage} className='w-full' ></Image>
          <div className='text-center'>组织架构</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticleDetail(365)}>
          <Image src={cbImage} className='w-full' ></Image>
          <div className='text-center'>网联会章程</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticleDetail(366)}>
          <Image src={ccImage} className='w-full'></Image>
          <div className='text-center'>会员单位</div>
        </Grid.Item>
        
      </Grid>
    </>
  )
};

export default PartC;