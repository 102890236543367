import { Route, Routes, BrowserRouter } from "react-router-dom";

import HomePage from '@/pages/home';
import PartA from '@/pages/part-a';
import PartB from '@/pages/part-b';
import PartC from '@/pages/part-c';
import PartD from '@/pages/part-d';
import ArticleList from '@/pages/article';
import ArticleDetail from '@/pages/article/detail';
import ArticlePartC from '@/pages/article/part-c';
import PartyMember from '@/pages/party-member';
import MemberDetail from '@/pages/party-member/detail';
import PartyShow from '@/pages/party-show';
import PartyShowDetail from '@/pages/party-show/detail';

function Routers () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/part-a" element={<PartA />} />
        <Route path="/part-b" element={<PartB />} />
        <Route path="/part-c" element={<PartC />} />
        <Route path="/part-d" element={<PartD />} />
        <Route path="/article" element={<ArticleList />} />
        <Route path="/article-partc" element={<ArticlePartC />} />
        <Route path="/party-member" element={<PartyMember />} />
        <Route path="/party-member-detail" element={<MemberDetail />} />
        <Route path="/party-show" element={<PartyShow />} />
        <Route path="/party-show-detail" element={<PartyShowDetail />} />
        <Route path="/article-detail" element={<ArticleDetail />} />
      </Routes>
    </BrowserRouter>
  )
}


export default Routers;