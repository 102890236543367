
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getPartyMemberDetail } from '@/apis';
import CoustomNavBar from '@/components/navbar.js';
function MemberDetail() {
  const location = useLocation();
  const { state } = location;
  const [detail, setDetail] = useState({});

  useEffect(() => {
    getPartyMemberDetail(state.id).then(res => {
      setDetail(res.data.param);
    })
  }, [])

  return (
    <>
      <div className="party-detail-container">
        <CoustomNavBar back="" title="掌上党建 —— 党员风采" backgroundColor="#A60A10" />
        <div className="partyMember-detail-box">
          <img src={detail.headImageUrl} width={200} align="left" hspace="40" vspace="10" />
          <div className="member-name">{detail.name}</div>
          <div className="member-baseinfo">学历：{detail.education}</div>
          <div className="member-baseinfo">党龄：{detail.partyAge}年</div>
          <div className="member-baseinfo">{detail.company}</div>
          <div className="member-baseinfo">{detail.partyName}</div>
          <div className="member-baseinfo">{detail.summary}</div>
        </div>
      </div>
    </>
  )
};

export default MemberDetail;