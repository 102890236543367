import CoustomNavBar from '@/components/navbar.js';
import { useNavigate } from 'react-router-dom';
import { Image, Grid } from 'antd-mobile';

const baImage = require('@/assets/b/ba.png');
const bbImage = require('@/assets/b/bb.png');
const bcImage = require('@/assets/b/bc.png');
const bdImage = require('@/assets/b/bd.png');
function PartB() {
  const navigate = useNavigate();

  const jumpToArticle = (title, typeId) => {
    navigate('/article', { state: { title, typeId }} )
  }

  const jumpToPartyShow = () => {
    navigate('/party-show')
  }

  return (
    <>
      <CoustomNavBar back="" title="E星基地 —— 同心众创联盟" backgroundColor="#C90406" />
      <Image className='page-main-image' src={require('@/assets/b/b.png')} />
      <Grid columns={2} gap={24} className='grid-custom'>
        <Grid.Item onClick={jumpToPartyShow}>
          <Image src={baImage} className='w-full'></Image>
          <div className='text-center'>同心风采</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticle('同心众创联盟 —— 同心力量', 'bd')}>
          <Image src={bbImage} className='w-full' ></Image>
          <div className='text-center'>同心力量</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticle('同心众创联盟 —— 同心建言', 'bc')}>
          <Image src={bcImage} className='w-full' ></Image>
          <div className='text-center'>同心建言</div>
        </Grid.Item>
        <Grid.Item onClick={() => jumpToArticle('同心众创联盟 —— 同心理论', 'ba')}>
          <Image src={bdImage} className='w-full' ></Image>
          <div className='text-center'>同心理论</div>
        </Grid.Item>
      </Grid>
    </>
  )
};

export default PartB;