
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getPartyCoalitionDetail } from '@/apis';
import CoustomNavBar from '@/components/navbar.js';
function PartyShowDetail() {
  const location = useLocation();

  const [detail, setDetail] = useState({});

  useEffect(() => {
    getPartyCoalitionDetail(location.state?.id).then(res => {
      let param = res.data.param;
      param.summary = param.summary.replace(/<img src="\//g, '<img src="https://smart.cmvstv.com/');
      setDetail(param);
    })
  }, [])


  return (
    <>
      <div className="party-show-detail-container">
        <CoustomNavBar back="" title="众创园同心联盟 —— 同心风采" backgroundColor="#A60A10" />
        <div className="party-show-detail-box">
          <img src={detail.headImageUrl} className="party-show-detail-avatar" />
          <div className="party-show-detail-desc-box">
            <div className="party-show-detail-name">{detail.name}</div>
            <div className="party-show-detail-desc">学历：{detail.education}</div>
            <div className="party-show-detail-desc">所属单位：{detail.company}</div>
          </div>
        </div>
        <div className="party-show-detail-summary" dangerouslySetInnerHTML={{ __html: detail.summary }}></div>
      </div>
    </>
  )
};

export default PartyShowDetail;