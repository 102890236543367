
import CoustomNavBar from '@/components/navbar.js';
import { useState, useEffect } from "react";
import { Grid, Card, Avatar } from 'antd-mobile';
import { getPartyCoalitionList } from '@/apis';
import { useNavigate } from 'react-router-dom';

function PartyShow() {
  const navigator = useNavigate();
  const [list, setList] = useState([]);

  useEffect(() => {
    getPartyCoalitionList().then(res => {
      let params = res.data.param;
      setList(params);
    })
  }, [])

  const jumptToDetail = (id) => {
    navigator('/party-show-detail', {
      state: {
        id
      }
    })
  }

  return (
    <>
      <CoustomNavBar back="" title="众创园同心联盟 —— 同心风采" backgroundColor="#A60A10" />
      <Grid columns={3} gap={24} className='grid-custom'>
        {
          list.map((item, index) => (
            <Grid.Item key={index} onClick={() => jumptToDetail(item.id)}>
              <Card className='party-show-box'>
                <Avatar src={item.headImageUrl} style={{ '--border-radius': '50%', '--size': '100px' }} className="party-show-avatar"></Avatar>
                <div className='party-show-name'>{item.name}</div>
                <div className='party-show-organization'>{item.company}</div>
              </Card>
            </Grid.Item>
          ))
        }
      </Grid>
    </>
  )
};

export default PartyShow;