import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPartyMemberList } from '@/apis';
import { Card, Grid, Avatar } from 'antd-mobile';
import CoustomNavBar from '@/components/navbar.js';
function PartyMember() {
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPartyMemberList().then(res => {
      let params = res.param;
      params.forEach(item => {
        item.headImageUrl = 'https://sadmin.cmvstv.com' + item.headImageUrl;
      })
      setList(params);
    })
  }, [])

  const jumpToDetail = (id) => {
    navigate(`/party-member-detail`, {
      state: {
        id
      }
    })
  }

  return (
    <>
      <CoustomNavBar back="" title="掌上党建 —— 党员风采" backgroundColor="#A60A10" />
      <div className="partyMember-container">
        <Grid columns={2} gap={20} className='member-grid-custom'>
          {
            list.map((item, index) => (
              <Grid.Item key={index} onClick={() => jumpToDetail(item.id)}>
                <Card className="card-member">
                  <div className='card-box-member'>
                    <Avatar src={item.headImageUrl}  style={{ '--size': '96px', '--border-radius': '20px' }} className='member-avatar'></Avatar>
                    <div className='card-member-info'>
                      <div className='member-name'>{item.name}</div>
                      <div className='member-baseinfo'>党龄：{item.partyAge}年</div>
                      <div className='member-baseinfo'>学历：{item.education}</div>
                    <div className='member-organization'>{item.partyName}</div>
                    </div>
                  </div>
                </Card>
              </Grid.Item>
            ))
          }
        </Grid>
      </div>
    </>
  )
};

export default PartyMember;